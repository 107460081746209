import Vue from "vue";
import { supabase } from "../supabase/supabase";

async function getGenres() {
  let response = await supabase.from("genre").select();
  let genres = [];
  if (response.data && response.data.length > 0) {
    genres = response.data;
  }
  return genres;
}

async function getGenreProjects(genreId) {
  let response = await supabase
    .from("project")
    .select()
    .match({ genre_id: genreId });
  if (response.error) {
    let notify = {
      duration: 4000,
      type: "error",
      headline: "Loading Projects",
      message: "Failed loading Projects. Please try again later",
    };
    Vue.prototype.$globalState.addNotification(notify);
  } else if (response.data && response.data.length > 0) {
    return response.data;
  }
  return [];
}

export { getGenres, getGenreProjects };
