<template>
  <div :style="gridContainerStyle">
    <div v-for="(image, index) in images" :key="index">
      <imageDetail
        :src="image.key"
        :indexInArray="image.index"
        :array="images"
      />
    </div>
  </div>
</template>
<script>
import imageDetail from "../components/imageDetail.vue";

import { supabase } from "../plugins/supabase/supabase";
export default {
  name: "highlightGrid",
  data() {
    return {
      images: [],
    };
  },

  components: {
    imageDetail,
  },
  async mounted() {
    await this.getHighlights();
  },
  methods: {
    getHighlights: async function () {
      let response = await supabase
        .from("image")
        .select()
        .match({ highlight: true });

      if (!response.error && response.data.length > 0) {
        this.imageAmount = response.data.length;
        this.images = this.shuffleArray(response.data);
      }
    },
    shuffleArray: function (array) {
      let currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle...
      while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      //add index as property to every item in array
      array.forEach((item, index) => {
        item.index = index;
      });

      return array;
    },
  },
  computed: {
    gridContainerStyle: function () {
      if (this.$vuetify.breakpoint.xsOnly) {
        return "display: grid; grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); grid-gap: 10px; grid-auto-rows: minmax(150px, 1fr);";
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        return "display: grid; grid-template-columns: repeat(auto-fit, minmax(170px, 1fr)); grid-gap: 10px; grid-auto-rows: minmax(170px, 1fr);";
      } else {
        return "display: grid; grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); grid-gap: 10px; grid-auto-rows: minmax(300px, 1fr);";
      }
    },
  },
};
</script>
<style scoped></style>
