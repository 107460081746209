<template>
  <div>
    <v-container fluid class="pt-16">
      <v-row v-for="project in projects" :key="project.id">
        <div
          class="hidden-md-and-down"
          :style="'width:' + $globalState.drawerWidth + 'px'"
        ></div>
        <v-col>
          <projectGrid
            :project="project"
            :id="'p-' + project.id"
            class="px-3"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import projectGrid from "../components/projectGrid.vue";
import { getGenreProjects } from "../plugins/vuetify/helpers";
export default {
  name: "Portrait",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: this.getGenreName(this.$route.params.item),
      meta: [
        {
          name: "description",
          content:
            this.getGenreName(this.$route.params.item) +
            " Portfolio of " +
            this.$applicationName,
        },
      ],
    };
  },
  components: {
    projectGrid,
  },
  beforeRouteEnter(to, from, next) {
    getGenreProjects(to.params.item).then((response) => {
      next((vm) => {
        vm.projects = response;
      });
    });
  },
  mounted() {
    if (
      !this.$route.params ||
      !this.validatePortfolioItem(this.$route.params.item)
    ) {
      this.$router.push({ name: "Portfolio" });
    } else if (this.$route.query && this.$route.query.p) {
      this.$router.push({
        name: "PortfolioItem",
        params: { item: this.$route.params.item },
        hash: "#p-" + this.$route.query.p,
      });
    }
  },
  watch: {
    "$route.params.item": async function () {
      this.projects = await getGenreProjects(this.$route.params.item);
    },
  },
  data() {
    return {
      projects: [],
      currentGenreId: this.$route.params.item,
    };
  },
  methods: {
    validatePortfolioItem: function (item) {
      let validItems = this.$globalState.genres.filter((genre) => {
        return genre.id === item;
      });
      if (validItems.length > 0) {
        return true;
      }
      return false;
    },
    getGenreName: function (id) {
      return this.$globalState.genres.filter((genre) => {
        return genre.id === id;
      })[0].name;
    },
  },
};
</script>
<style scoped></style>
