<template>
  <div>
    <v-img
      class="fullHeight"
      src="https://j4rg-cdn.fra1.cdn.digitaloceanspaces.com/public/static/image/landing/landing.webp"
    >
      <v-row>
        <v-col class="d-flex justify-end align-center mt-4">
          <v-spacer v-if="!$vuetify.breakpoint.mdAndUp"></v-spacer>
          <router-link
            to="portfolio#highlights"
            :class="
              $vuetify.breakpoint.mdAndDown
                ? 'text-uppercase background--text px-3'
                : 'text-uppercase primary--text px-6'
            "
            :style="navigationTextStyle"
            >Portfolio</router-link
          >
          <router-link
            to="/#about"
            :class="
              $vuetify.breakpoint.mdAndDown
                ? 'text-uppercase background--text px-3'
                : 'text-uppercase primary--text px-6'
            "
            :style="navigationTextStyle"
            >Meet J4PEG Visuals</router-link
          >
          <v-btn icon class="px-4" @click="handleInstagram">
            <v-icon
              :color="$vuetify.breakpoint.mdAndDown ? 'background' : 'primary'"
            >
              mdi-instagram
            </v-icon>
          </v-btn>
          <adminMenu class="px-4" v-if="$vuetify.breakpoint.lgAndUp" />
          <v-spacer v-if="!$vuetify.breakpoint.mdAndUp"></v-spacer>
        </v-col>
      </v-row>
      <div :style="landingTextStyle">
        <v-row>
          <v-col class="d-flex justify-center py-0">
            <J4pegIcon colorClass="primary" :width="iconSize" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-1 d-flex justify-center">
            <span class="primary--text" :style="sloganTextStyle"
              >change your perspective.</span
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <div :style="contactOutlineStyle">
              <div class="grow">
                <!-- TODO - DISABLED until m8motion contact form is ready -->
                <!-- <a
                  href="https://m8motion.com/contact"
                  target="_blank"
                  class="text-uppercase primary--text px-4"
                  :style="contactTextStyle"
                  >Contact Me</a
                > -->
                <a
                  href="mailto:andreas@m8motion.com"
                  class="text-uppercase primary--text px-4"
                  :style="contactTextStyle"
                  >Contact Me</a
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-img>
    <v-container class="fullHeight" id="about">
      <v-row class="pt-10">
        <v-col class="hidden-sm-and-down" cols="1"></v-col>
        <v-col md="10" class="d-flex justify-center">
          <span class="text-uppercase" :style="headlineStyle"
            >Meet J4PEG Visuals</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" cols="3"></v-col>
        <v-col md="6">
          <div class="text-center">
            <span class="font-italic"
              >„Change your perspective in order to change your
              experience.“</span
            >
          </div>
          <div class="px-4 pb-4 text-justify">
            <br />
            <br />
            Hello there! My name is Andreas, I’m a professional photographer and
            videographer based in Hamburg, Germany. And I love my camera. Here
            is why.
            <br />
            <br />
            Ever since I picked up my first camera, the way I perceive my
            surrounding changed completely. With this camera I’m able to capture
            the beauty of the moment in a picture in order to express the
            feelings connected to it. I personally think that cameras are more
            than just a tool, they are an opportunity to reflect your vision. I
            took a step into the unknown without any expectations. But this step
            led me to the discovery of my true passion: Photography and
            Videography.
            <br />
            <br />
            I’m always striving to improve and create something that matters.
            With the best visuals possible. As I continue my journey, there is
            one simple question left: Do you want to join me on the way?
          </div>
        </v-col>
      </v-row>
      <v-row class="pb-6">
        <v-col class="hidden-sm-and-down" cols="3"></v-col>
        <v-col md="6" class="d-flex justify-center">
          <!-- TODO - DISABLED until m8motion contact form is ready -->
          <!-- <a
                  href="https://m8motion.com/contact"
                  target="_blank"
                  class="font-weight-bold text-uppercase primary-text"
                  :style="projectHeadlineStyle"
                  >CONTACT ME HERE</a
                > -->
          <a
            href="mailto:andreas@m8motion.com"
            class="
              font-weight-bold
              text-uppercase
              primary-text
              text-decoration-none
              pb-5
            "
            :style="projectHeadlineStyle"
            >Contact Me Here</a
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" cols="1"></v-col>
        <v-col md="10" class="d-flex justify-center pb-10">
          <span
            class="text-uppercase"
            :style="headlineStyle"
            @click="$router.push({ name: 'Portfolio', hash: '#highlights' })"
          >
            Portfolio</span
          >
        </v-col>
      </v-row>
      <v-row class="hidden-sm-and-down">
        <v-col style="width: 5%" class="hidden-sm-and-down"></v-col>
        <v-col style="width: 30%" class="d-flex justify-center">
          <portfolioCard
            :genreId="streetId"
            imgSrc="https://j4rg-cdn.fra1.cdn.digitaloceanspaces.com/public/static/image/portfoliocard/streets.webp"
            lazySrc="https://j4rg-cdn.fra1.cdn.digitaloceanspaces.com/public/static/image/portfoliocard/streets_lazy.webp"
            title="street"
          />
        </v-col>
        <v-col style="width: 30%" class="d-flex justify-center">
          <portfolioCard
            :genreId="travelId"
            imgSrc="https://j4rg-cdn.fra1.cdn.digitaloceanspaces.com/public/static/image/portfoliocard/travel.webp"
            lazySrc="https://j4rg-cdn.fra1.cdn.digitaloceanspaces.com/public/static/image/portfoliocard/travel_lazy.webp"
            title="travel"
          />
        </v-col>
        <v-col style="width: 30%" class="d-flex justify-center">
          <portfolioCard
            :genreId="lifestyleId"
            imgSrc="https://j4rg-cdn.fra1.cdn.digitaloceanspaces.com/public/static/image/portfoliocard/lifestyle.webp"
            lazySrc="https://j4rg-cdn.fra1.cdn.digitaloceanspaces.com/public/static/image/portfoliocard/lifestyle_lazy.webp"
            title="lifestyle"
          />
        </v-col>
        <v-col style="width: 5%" class="hidden-sm-and-down"></v-col>
      </v-row>
      <div class="hidden-md-and-up">
        <v-row>
          <v-col>
            <portfolioCard
              style="height: 40vh"
              :genreId="streetId"
              imgSrc="https://j4rg-cdn.fra1.cdn.digitaloceanspaces.com/public/static/image/portfoliocard/streets.webp"
              lazySrc="https://j4rg-cdn.fra1.cdn.digitaloceanspaces.com/public/static/image/portfoliocard/streets_lazy.webp"
              title="street"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <portfolioCard
              style="height: 40vh"
              :genreId="travelId"
              imgSrc="https://j4rg-cdn.fra1.cdn.digitaloceanspaces.com/public/static/image/portfoliocard/travel.webp"
              lazySrc="https://j4rg-cdn.fra1.cdn.digitaloceanspaces.com/public/static/image/portfoliocard/travel_lazy.webp"
              title="travel"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <portfolioCard
              style="height: 40vh"
              :genreId="lifestyleId"
              imgSrc="https://j4rg-cdn.fra1.cdn.digitaloceanspaces.com/public/static/image/portfoliocard/lifestyle.webp"
              lazySrc="https://j4rg-cdn.fra1.cdn.digitaloceanspaces.com/public/static/image/portfoliocard/lifestyle_lazy.webp"
              title="lifestyle"
            />
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col class="hidden-sm-and-down" cols="1"></v-col>
        <v-col md="10" class="d-flex justify-center pt-12">
          <span class="text-uppercase" :style="headlineStyle"
            >Recent Projects</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" cols="1"></v-col>
        <v-col md="10">
          <v-hover v-slot="{ hover }">
            <v-carousel
              :cycle="!hover"
              show-arrows-on-hover
              hide-delimiters
              interval="3000"
            >
              <v-carousel-item
                v-for="(carouselItem, index) in carouselItems"
                :key="index"
              >
                <v-row>
                  <v-col v-for="item in carouselItem" :key="item.id">
                    <v-row>
                      <v-col
                        class="d-flex justify-center text-uppercase pb-6"
                        :style="projectHeadlineStyle"
                      >
                        {{ item.title }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex justify-center">
                        <imageDetail
                          v-if="item.titleImage && item.titleImage.key"
                          :src="item.titleImage.key"
                          :width="projectWidth"
                          :hoverText="item.description"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-carousel-item>
            </v-carousel>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import adminMenu from "../components/adminMenu.vue";
import imageDetail from "../components/imageDetail.vue";
import portfolioCard from "../components/portfolioCard.vue";

import { supabase } from "../plugins/supabase/supabase";
import J4pegIcon from "../components/j4pegIcon.vue";
export default {
  name: "Home",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Home",
      meta: [
        {
          name: "description",
          content: "Home of " + this.$applicationName,
        },
      ],
    };
  },
  computed: {
    iconSize: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 50;
        case "sm":
          return 50;
        case "md":
          return 60;
        case "lg":
          return 90;
        case "xl":
          return 100;
      }
      return 100;
    },
    landingTextStyle: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "position: absolute; bottom: 44%; left: 50%; transform: translateX(-50%); width: 100%;";
        case "sm":
          return "position: absolute; bottom: 44%; left: 50%; transform: translateX(-50%); width: 100%;";
        case "md":
          return "position: absolute; bottom: 43%; left: 50%; transform: translateX(-50%); width: 100%;";
        case "lg":
          return "position: absolute; bottom: 42%; left: 50%; transform: translateX(-50%); width: 100%;";
        case "xl":
          return "position: absolute; bottom: 43%; left: 50%; transform: translateX(-50%); width: 100%;";
      }
      return "position: absolute; bottom: 43%; left: 50%; transform: translateX(-50%); width: 100%;";
    },
    contactOutlineStyle: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "outline: solid 1px; outline-color: var(--v-primary-base);";
        case "sm":
          return "outline: solid 1px; outline-color: var(--v-primary-base); ";
        case "md":
          return "outline: solid 1px; outline-color: var(--v-primary-base);";
        case "lg":
          return "outline: solid 2px; outline-color: var(--v-primary-base); padding-top: 7px; padding-bottom: 7px;";
        case "xl":
          return "outline: solid 2px; outline-color: var(--v-primary-base); padding-top: 7px; padding-bottom: 7px;";
      }
      return "outline: solid 2px; outline-color: var(--v-primary-base); padding-top: 7px; padding-bottom: 7px;";
    },
    sloganTextStyle: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "letter-spacing: 1em; font-size: 2.5vw;";
        case "sm":
          return "letter-spacing: 1em; font-size: 2.4vw;";
        case "md":
          return "letter-spacing: 1em; font-size: 1.7vw;";
        case "lg":
          return "letter-spacing: 1em; font-size: 1.4vw;";
        case "xl":
          return "letter-spacing: 1em; font-size: 1.4vw;";
      }
      return "letter-spacing: 1em; font-size: 1.4vw;";
    },
    navigationTextStyle: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "letter-spacing: 0.4em; font-size: 0.7em; font-weight: bold; text-decoration: none";
        case "sm":
          return "letter-spacing: 0.4em; font-size: 0.7em; font-weight: bold; text-decoration: none";
        case "md":
          return "letter-spacing: 0.4em; font-size: 0.75em; font-weight: bold; text-decoration: none";
        case "lg":
          return "letter-spacing: 0.4em; font-size: 0.7em; font-weight: bold; text-decoration: none";
        case "xl":
          return "letter-spacing: 0.4em; font-size: 0.7em; font-weight: bold; text-decoration: none";
      }
      return "letter-spacing: 0.4em; font-size: 0.7em; font-weight: bold; text-decoration: none";
    },
    contactTextStyle: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "letter-spacing: 0.5em; font-size: 0.5em; text-decoration: none";
        case "sm":
          return "letter-spacing: 0.5em; font-size: 0.7em; text-decoration: none";
        case "md":
          return "letter-spacing: 0.5em; font-size: 0.9em; text-decoration: none";
        case "lg":
          return "letter-spacing: 0.5em; font-size: 1.1em; text-decoration: none";
        case "xl":
          return "letter-spacing: 0.5em; font-size: 1.1em; text-decoration: none";
      }
      return "letter-spacing: 0.5em; font-size: 1.1em; text-decoration: none";
    },
    headlineStyle: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "letter-spacing: 0.1em; font-size: 1.3em; font-weight: bold;";
        case "sm":
          return "letter-spacing: 0.1em; font-size: 1.3em; font-weight: bold;";
        case "md":
          return "letter-spacing: 0.1em; font-size: 1.5em; font-weight: bold;";
        case "lg":
          return "letter-spacing: 0.1em; font-size: 2.1em; font-weight: bold;";
        case "xl":
          return "letter-spacing: 0.1em; font-size: 2.1em; font-weight: bold;";
      }
      return "letter-spacing: 0.1em; font-size: 2.1em; font-weight: bold;";
    },
    projectHeadlineStyle: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "letter-spacing: 0.25em; font-size: 0.8em;";
        case "sm":
          return "letter-spacing: 0.25em; font-size: 0.9em;";
        case "md":
          return "letter-spacing: 0.25em; font-size: 0.9em;";
        case "lg":
          return "letter-spacing: 0.25em; font-size: 1.1em;";
        case "xl":
          return "letter-spacing: 0.25em; font-size: 1.1em;";
      }
      return "letter-spacing: 0.25em; font-size: 1.1em;";
    },
  },
  components: {
    adminMenu,
    imageDetail,
    portfolioCard,
    J4pegIcon,
  },
  data() {
    return {
      lifestyleId: null,
      travelId: null,
      streetId: null,
      projects: [],
      projectWidth: 300,
      carouselItems: null,
    };
  },
  async mounted() {
    if (this.$route.query.message) {
      let notify = {
        duration: 4000,
        type: "info",
        headline: this.$route.query.message,
        message: "",
      };
      this.$globalState.addNotification(notify);
    }
    await this.getGenreIds();
    await this.getProjects();
    this.generateCarouselItems();
  },
  methods: {
    getGenreIds: async function () {
      let response = await supabase.from("genre").select();
      if (!response.error && response.data.length > 0) {
        this.lifestyleId = response.data.filter((genre) => {
          return genre.name === "Lifestyle";
        });

        if (this.lifestyleId.length > 0) {
          this.lifestyleId = this.lifestyleId[0];

          if (this.lifestyleId.id) {
            this.lifestyleId = this.lifestyleId.id;
          }
        } else {
          this.lifestyleId = null;
        }

        this.travelId = response.data.filter((genre) => {
          return genre.name === "Travel";
        });

        if (this.travelId.length > 0) {
          this.travelId = this.travelId[0];

          if (this.travelId.id) {
            this.travelId = this.travelId.id;
          }
        } else {
          this.travelId = null;
        }

        this.streetId = response.data.filter((genre) => {
          return genre.name === "Street";
        });

        if (this.streetId.length > 0) {
          this.streetId = this.streetId[0];

          if (this.streetId.id) {
            this.streetId = this.streetId.id;
          }
        } else {
          this.streetId = null;
        }
      }
    },
    handleInstagram: function () {
      let instaUrl = "https://www.instagram.com/j4peg.visuals/";
      window.open(instaUrl, "_blank").focus();
    },
    getProjects: async function () {
      let response = await supabase
        .from("project")
        .select()
        .match({ highlight: true });

      if (!response.error && response.data.length > 0) {
        let projects = response.data;

        for (let i = 0; i < projects.length; i++) {
          let project = projects[i];

          response = await supabase
            .from("image")
            .select()
            .match({ title: true, project_id: project.id });

          if (!response.error && response.data.length > 0) {
            project.titleImage = response.data[0];
          }

          this.projects.push(project);
        }
      }
    },
    generateCarouselItems: function () {
      if (this.projects.length > 0) {
        let projectsPerCarouselItem = Math.ceil(
          (this.$vuetify.breakpoint.width * 0.4) / this.projectWidth
        );
        let carouselItems = [];
        let amountCarouselItems =
          this.projects.length / projectsPerCarouselItem;

        for (let i = 0; i < amountCarouselItems; i++) {
          let carouselItem = [];
          for (let j = 0; j < projectsPerCarouselItem; j++) {
            if (this.projects.length > 0) {
              carouselItem.push(this.projects.shift());
            }
          }
          carouselItems.push(carouselItem);
        }
        this.carouselItems = carouselItems;
      }
    },
  },
};
</script>

<style scoped>
.fullHeight {
  height: 100vh;
}
.wrapper {
  position: relative;
}
.spacedletters2 {
  letter-spacing: 0.5em;
  font-size: 1vw;
}
.grow {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.grow:hover {
  transform: scale(1.1);
}
.scrollableX {
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
}
</style>
