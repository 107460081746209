var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("Upload to CDN")]),_c('v-stepper',{attrs:{"elevation":"0"},model:{value:(_vm.uploadStep),callback:function ($$v) {_vm.uploadStep=$$v},expression:"uploadStep"}},[_c('v-stepper-header',{staticClass:"elevation-0"},[_c('v-stepper-step',{attrs:{"complete":_vm.uploadStep > 1,"step":"1"}},[_vm._v(" Project Select ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.uploadStep > 2,"step":"2"}},[_vm._v(" Select & Crop Images ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.uploadStep > 3,"step":"3"}},[_vm._v(" Select Title Image ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"4"}},[_vm._v(" Select Highlights ")])],1),_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"pt-0",attrs:{"step":"1"}},[_c('v-container',[_c('v-form',{ref:"projectSelect"},[_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Project","rules":[_vm.notEmpty],"items":_vm.projects,"item-text":"title","item-value":"id"},on:{"change":_vm.prepareImageSelect},model:{value:(_vm.stepper.uploadProject),callback:function ($$v) {_vm.$set(_vm.stepper, "uploadProject", $$v)},expression:"stepper.uploadProject"}})],1)],1)],1)],1),_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"error"},on:{"click":_vm.resetStepper}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.stepper.uploadProject},on:{"click":function($event){_vm.uploadStep = 2}}},[_vm._v(" Continue ")])],1),_c('v-stepper-content',{staticClass:"pt-0",attrs:{"step":"2"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('cropPanels',{ref:"cropPanels",on:{"cancel":_vm.resetStepper,"back":function($event){_vm.uploadStep = 1},"continue":function (images) {
                      _vm.uploadStep = 3;
                      _vm.stepper.images = images;
                    }}})],1)],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"3"}},[(!_vm.stepper.existingTitleImage)?_c('v-container',[_c('v-row',[_c('v-col',[(_vm.stepper.images && _vm.stepper.images.length > 0)?_c('div',[_c('div',{staticClass:"scrollable"},[_c('v-item-group',{model:{value:(_vm.stepper.titleImageIndex),callback:function ($$v) {_vm.$set(_vm.stepper, "titleImageIndex", $$v)},expression:"stepper.titleImageIndex"}},[_c('v-row',[_c('v-col',[_c('div',{style:(_vm.gridContainerStyle)},_vm._l((_vm.stepper.images),function(image,index){return _c('div',{key:index},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var active = ref.active;
                    var toggle = ref.toggle;
return [_c('v-img',{attrs:{"src":image.src,"contain":""},on:{"click":toggle}},[(active)?_c('v-container',[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"icon":"","dark":""}},[_c('v-icon',{attrs:{"color":"success","large":""}},[_vm._v(" mdi-check-decagram ")])],1)],1)],1)],1):_vm._e()],1)]}}],null,true)})],1)}),0)])],1)],1)],1)]):_vm._e()])],1)],1):_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end align-center"},[(_vm.stepper.existingTitleImage)?_c('imageDetail',{attrs:{"src":_vm.stepper.existingTitleImage.key,"width":_vm.maxWidthImages}}):_vm._e()],1),_c('v-col',{staticClass:"d-flex justify-start align-center"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Title-Image already selected")])])],1)],1),_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"error"},on:{"click":_vm.resetStepper}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.uploadStep = _vm.uploadStep - 1}}},[_vm._v("Back")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.stepper.titleImageIndex === null && !_vm.stepper.existingTitleImage},on:{"click":function($event){_vm.uploadStep = 4}}},[_vm._v(" Continue ")])],1),_c('v-stepper-content',{attrs:{"step":"4"}},[_c('v-container',[_c('v-row',[_c('v-col',[(_vm.stepper.images && _vm.stepper.images.length > 0)?_c('div',[_c('div',{staticClass:"scrollable"},[_c('v-item-group',{attrs:{"multiple":""},model:{value:(_vm.stepper.highlightIndexes),callback:function ($$v) {_vm.$set(_vm.stepper, "highlightIndexes", $$v)},expression:"stepper.highlightIndexes"}},[_c('v-row',[_c('v-col',[_c('div',{style:(_vm.gridContainerStyle)},_vm._l((_vm.stepper.images),function(image,index){return _c('div',{key:index},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var active = ref.active;
                    var toggle = ref.toggle;
return [_c('v-img',{attrs:{"src":image.src,"contain":""},on:{"click":toggle}},[(active)?_c('v-container',[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"icon":"","dark":""}},[_c('v-icon',{attrs:{"color":"success","large":""}},[_vm._v(" mdi-check-decagram ")])],1)],1)],1)],1):_vm._e()],1)]}}],null,true)})],1)}),0)])],1)],1)],1)]):_vm._e()])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"error"},on:{"click":_vm.resetStepper}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary"},on:{"click":function($event){_vm.uploadStep = _vm.uploadStep - 1}}},[_vm._v(" Back ")]),(_vm.uploadPercentage === 100 && _vm.uploadLoading === false)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.resetStepper}},[_vm._v(" Finish ")]):_c('v-btn',{attrs:{"color":"success","loading":_vm.uploadLoading},on:{"click":_vm.upload}},[_vm._v(" Upload ")])],1),_c('v-col',{staticClass:"d-flex align-center"},[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.currentAction)+" ")])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-progress-linear',{attrs:{"indeterminate":_vm.uploadPercentage === 0,"value":_vm.uploadPercentage,"color":"primary"}})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }