<template>
  <div>
    <v-form ref="fileInput">
      <v-file-input
        counter
        show-size
        truncate-length="15"
        multiple
        v-model="files"
        @change="prepareFiles"
      ></v-file-input>
    </v-form>
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <v-expansion-panels
        v-if="files.length > 0 && images.length === files.length"
        flat
        class="panel"
      >
        <v-expansion-panel
          v-for="(image, index) in images"
          :key="image.src + 'd'"
        >
          <v-expansion-panel-header @click="resetPreview">
            <v-row no-gutters>
              <v-col>
                {{ image.name }} - ({{ image.width }} x {{ image.height }})
              </v-col>
              <v-col class="d-flex justify-end align-center">
                <v-icon v-if="image.cropped" class="mx-2" color="primary"
                  >mdi-crop</v-icon
                >
                <v-tooltip v-if="image.ratio != 1" left color="error">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="mx-2" color="error"
                      >mdi-alert-circle</v-icon
                    >
                  </template>
                  <span>Must be cropped!</span>
                </v-tooltip>

                <v-icon v-else class="mx-2" color="success">mdi-check</v-icon>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col class="d-flex justify-center">
                <Span>Crop</Span>
              </v-col>
              <v-col class="d-flex justify-center">
                <span>Preview</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center">
                <div :style="cropperWrapperStyle">
                  <div
                    :style="{ backgroundImage: 'url(' + image.src + ')' }"
                    class="image-background"
                  ></div>
                  <!-- TODO blurred background cropper -->
                  <Cropper
                    :src="image.src"
                    @change="onChange"
                    :debounce="false"
                    :stencil-props="{
                      aspectRatio: 1,
                    }"
                    :default-position="{
                      top: 0,
                      left: 0,
                    }"
                    :default-size="defaultSize"
                    :style="cropperStyle"
                    background-class="cropper-background"
                  />
                </div>
              </v-col>

              <v-col class="d-flex justify-center">
                <Preview
                  :width="previewWidth"
                  :height="previewWidth"
                  :image="result.image"
                  :coordinates="result.coordinates"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center align-center">
                <v-btn
                  @click="crop(index)"
                  :disabled="!image.src"
                  color="primary"
                >
                  Crop
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div v-else>
      <v-row>
        <v-col class="d-flex justify-center font-weight-bold">
          Crop is only available on Tablets and Desktops
        </v-col>
      </v-row>
    </div>
    <v-btn outlined color="error" class="mr-4" @click="$emit('cancel')"
      >Cancel</v-btn
    >
    <v-btn color="primary" outlined class="mr-4" @click="$emit('back')"
      >Back</v-btn
    >
    <v-btn
      color="primary"
      class="mr-4"
      @click="selectImages"
      :disabled="files.length === 0 || !allImgRatio1"
    >
      Continue
    </v-btn>
  </div>
</template>
<script>
import { Cropper, Preview } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
export default {
  name: "cropPanels",
  data() {
    return {
      files: [],
      images: [],
      result: {},
      croppedImages: [],
    };
  },
  components: {
    Cropper,
    Preview,
  },
  computed: {
    cropperWrapperStyle: function () {
      return (
        "overflow: hidden; position: relative; height: " +
        this.previewWidth +
        "px; background: black;"
      );
    },
    allImgRatio1: function () {
      return this.images.every((img) => img.ratio === 1);
    },
    cropperStyle: function () {
      return (
        "width: " +
        this.previewWidth +
        "px; height: " +
        this.previewWidth +
        "px;"
      );
    },
    previewWidth: function () {
      if (this.$vuetify.breakpoint.width > 1635) {
        return 370;
      } else if (this.$vuetify.breakpoint.width > 1385) {
        return 300;
      } else if (this.$vuetify.breakpoint.width > 910) {
        return 200;
      } else if (this.$vuetify.breakpoint.width > 810) {
        return 150;
      } else {
        return 300;
      }
    },
  },
  methods: {
    resetPanel: function () {
      this.images = [];
      this.currentStep = 0;
      this.result = {};
      this.croppedImages = [];
      this.$refs.fileInput.reset();
    },
    selectImages: async function () {
      let images = this.images;
      let croppedImages = this.croppedImages;

      let selectedImages = [];

      for (let i = 0; i < images.length; i++) {
        if (croppedImages[i]) {
          selectedImages.push(croppedImages[i]);
        } else if (images[i]) {
          selectedImages.push({
            name: images[i].name,
            src: images[i].src,
            blob: new Blob(
              [new Uint8Array(await this.files[i].arrayBuffer())],
              { type: "image/webp" }
            ),
          });
        }
      }
      this.$emit("continue", selectedImages);
    },
    defaultSize({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
      };
    },
    crop: function (index) {
      this.result.canvas.toBlob((blob) => {
        let blobUrl = URL.createObjectURL(blob);
        this.croppedImages[index] = {
          src: blobUrl,
          blob: blob,
          name: this.images[index].name,
        };
        this.images[index].ratio = 1;
        this.images[index].cropped = true;
        let notify = {
          duration: 2000,
          type: "info",
          headline: "",
          message: "Successfully cropped",
        };
        this.$globalState.addNotification(notify);
      }, "image/webp");
    },
    resetPreview: function () {
      this.result = {};
    },
    onChange: function ({ coordinates, image, canvas }) {
      this.result = {
        coordinates,
        image,
        canvas,
      };
    },
    getImageDimensons: function (file) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => {
          resolve({
            width: img.width,
            height: img.height,
          });
        };
        img.onerror = () => {
          reject();
        };
        img.src = URL.createObjectURL(file);
      });
    },
    prepareFiles: async function () {
      this.images = [];
      this.croppedImages = [];
      this.result = {};

      let files = this.files;

      for (let i = 0; i < files.length; i++) {
        let file = files[i];

        if (file) {
          let dimensions = await this.getImageDimensons(file);
          let ratio = dimensions.width / dimensions.height;

          const blob = URL.createObjectURL(file);

          this.images.push({
            src: blob,
            name: file.name,
            width: dimensions.width,
            height: dimensions.height,
            ratio: ratio,
            cropped: false,
          });
        }
      }
    },
  },
};
</script>
<style>
.cropper-background {
  background: none;
}
.image-background {
  position: absolute;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  left: -10px;
  top: -10px;
  background-size: cover;
  background-position: 50%;
  filter: blur(5px);
}
</style>
