import { S3 } from "@aws-sdk/client-s3";
import { supabase } from "../supabase/supabase";

async function getS3Credentials(key) {
  let response = await supabase.from("secret").select().match({ key: key });
  if (response.data && response.data.length > 0) {
    return response.data[0].value;
  } else {
    return "";
  }
}

async function createClient() {
  return new S3({
    endpoint: "https://fra1.digitaloceanspaces.com",
    region: "fra1",
    credentials: {
      accessKeyId: await getS3Credentials("DO_SPACES_KEY"),
      secretAccessKey: await getS3Credentials("DO_SPACES_SECRET"),
    },
  });
}

const bucketName = "j4rg-cdn";

export { createClient, bucketName };
