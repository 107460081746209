<template>
  <div id="highlights">
    <v-container fluid class="pt-16">
      <v-row>
        <v-col class="d-flex justify-end">
          <div :class="$globalState.drawer ? 'drawerSpacer' : ''">
            <div :style="headlineStyle" class="pa-3">
              Enjoy and revisit some of my alltime Highlights here
            </div>
            <highlightGrid class="px-3" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import highlightGrid from "../components/highlightGrid.vue";
export default {
  name: "Portfolio",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Portfolio",
      meta: [
        {
          name: "description",
          content: "Portfolio view of " + this.$applicationName,
        },
      ],
    };
  },
  components: {
    highlightGrid,
  },
  computed: {
    headlineStyle: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return " font-size: 0.9em;";
        case "sm":
          return " font-size: 1.0em;";
        case "md":
          return " font-size: 1.1em;";
        case "lg":
          return " font-size: 1.2em;";
        case "xl":
          return " font-size: 1.4em;";
      }
      return " font-size: 1.4em;";
    },
  },
};
</script>
<style scoped>
.drawerSpacer {
  width: calc(100% - 170px);
}
</style>
