import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import dark from "./vuetify/themes/dark";
import light from "./vuetify/themes/light";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      dark: dark,
      light: light,
    },
    options: { customProperties: true },
  },
});
