<template>
  <div>
    <svg viewBox="0 0 6000.57 5994.99" :width="svgWidth" :height="svgHeight">
      <g
        transform="translate(0.000000,6000.000000) scale(0.300000,-0.300000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M9644 17593 l-140 -4 -4377 -7573 c-2407 -4166 -4377 -7577 -4377
-7580 0 -3 154 -6 343 -6 l343 0 1840 3184 c1011 1751 1840 3183 1842 3181 1
-1 -11 -56 -28 -122 -95 -384 -135 -734 -135 -1183 0 -307 8 -429 41 -680 101
-757 378 -1486 810 -2132 775 -1158 2011 -1952 3384 -2172 299 -48 461 -60
810 -60 349 0 511 12 810 60 1042 167 2006 661 2760 1414 782 782 1289 1803
1434 2890 33 251 41 373 41 680 0 440 -39 786 -130 1160 -13 57 -24 105 -22
106 1 1 821 -1416 1822 -3148 1001 -1733 1827 -3159 1835 -3170 14 -18 33 -18
357 -16 l342 3 -71 125 c-40 69 -1082 1873 -2316 4010 -1235 2137 -2247 3893
-2250 3902 -2 9 21 77 51 150 168 402 284 831 341 1258 33 251 41 373 41 680
0 307 -8 429 -41 680 -145 1087 -651 2107 -1434 2890 -744 743 -1696 1235
-2726 1410 -329 55 -724 76 -1200 63z m921 -579 c689 -86 1345 -331 1930 -720
1075 -715 1798 -1873 1965 -3146 20 -158 40 -400 40 -500 l0 -58 -2100 0
-2100 0 0 2226 0 2226 73 -7 c39 -3 126 -13 192 -21z m-865 -2387 l0 -2093
-182 -18 c-1071 -104 -2095 -550 -2873 -1254 -200 -180 -220 -197 -204 -172 8
14 744 1286 1634 2828 891 1541 1621 2802 1622 2802 2 0 3 -942 3 -2093z
m4756 -2704 c-4 -38 -24 -156 -46 -263 -251 -1255 -1052 -2370 -2160 -3007
-577 -332 -1197 -526 -1877 -588 l-73 -7 0 1966 0 1966 2081 0 2082 0 -7 -67z
m-4756 -4434 l0 -4491 -72 7 c-1088 99 -2050 546 -2808 1305 -626 625 -1048
1406 -1225 2262 -349 1691 288 3417 1651 4476 535 416 1182 714 1835 846 219
44 464 80 587 85 l32 1 0 -4491z m4441 1765 c474 -1109 477 -2391 8 -3508
-225 -535 -552 -1019 -969 -1436 -758 -759 -1720 -1206 -2807 -1305 l-73 -7 0
2254 0 2254 183 18 c1174 114 2252 622 3087 1456 145 146 294 312 399 447 l56
73 34 -68 c19 -37 55 -117 82 -178z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "NameSvg",
  props: {
    width: Number,
    height: Number,
    colorClass: String,
  },
  computed: {
    svgWidth: function () {
      if (this.width && this.width > 0) {
        return this.width;
      }
      return "100%";
    },
    svgHeight: function () {
      if (this.height && this.height > 0) {
        return this.height;
      }
      return "100%";
    },
  },
};
</script>

<style type="text/css">
.primary {
  fill: var(--v-primary-base);
}
.secondary {
  fill: var(--v-secondary-base);
}
.text {
  fill: var(--v-text-base);
}
.background {
  fill: var(--v-background-base);
}
.white {
  fill: #ffffff;
}
.black {
  fill: #000000;
}
.cls-1 {
  fill: transparent;
}
.cls-2 {
  fill: #020202;
}
.cls-3 {
  fill: red;
}
.cls-4 {
  fill: transparent;
}
.cls-5 {
  fill: transparent;
}
.cls-6 {
  fill: transparent;
}
.cls-7 {
  fill: transparent;
}
.cls-8 {
  fill: transparent;
}
.cls-9 {
  fill: transparent;
}
</style>
