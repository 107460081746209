<template>
  <div>
    <v-app-bar elevate-on-scroll fixed color="background">
      <v-btn icon @click="drawer = !drawer" class="hidden-lg-and-up">
        <v-icon> mdi-menu </v-icon>
      </v-btn>
      <div style="width: 200px" class="hidden-md-and-down"></div>
      <v-spacer></v-spacer>
      <span
        style="cursor: pointer"
        @click="$router.push({ name: 'Home' })"
        class="heading6 spacedletters2 text-uppercase px-4 hidden-md-and-down"
        >Home</span
      >
      <span
        style="cursor: pointer"
        @click="$router.push({ name: 'Portfolio', hash: '#highlights' })"
        class="heading6 spacedletters2 text-uppercase px-4 hidden-md-and-down"
        >Portfolio</span
      >
      <div
        @click="$router.push({ name: 'Home' })"
        style="cursor: pointer"
        class="px-4"
      >
        <J4pegIcon colorClass="text" :width="50" />
      </div>
      <span
        style="cursor: pointer; white-space: nowrap"
        @click="$router.push({ name: 'Home', hash: '#about' })"
        class="heading6 spacedletters2 text-uppercase px-4 hidden-md-and-down"
        >Meet J4PEG Visuals</span
      >
      <!-- TODO - DISABLED until m8motion contact form is ready -->
      <!-- <a
        href="https://m8motion.com/contact"
        target="_blank"
        style="cursor: pointer"
        class="
          heading6
          spacedletters2
          text-uppercase
          px-4
          hidden-md-and-down
          text-decoration-none
        "
        >Contact</a
      > -->
      <a
        href="mailto:andreas@m8motion.com"
        style="cursor: pointer"
        class="
          heading6
          spacedletters2
          text-uppercase
          px-4
          hidden-md-and-down
          text-decoration-none
        "
        >Contact</a
      >
      <v-spacer></v-spacer>
      <adminMenu class="hidden-md-and-down" />
      <v-btn icon class="hidden-lg-and-up" @click="handleAdminDrawer">
        <v-icon large color="text"> mdi-account </v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Drawer right for admin navigation -->

    <v-navigation-drawer right fixed v-model="adminDrawer">
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon large>mdi-account</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-if="$globalState.profile">{{
              $globalState.profile.display_name
            }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-btn @click="handleSignOut" icon>
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item link to="/cdnmanager">
          <v-list-item-icon>
            <v-icon>mdi-cloud-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>CDN-Manager</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/projectmanager">
          <v-list-item-icon>
            <v-icon>mdi-folder-image</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Project-Manager</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/genremanager">
          <v-list-item-icon>
            <v-icon>mdi-palette-swatch</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Genre-Manager</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/profile">
          <v-list-item-icon>
            <v-icon>mdi-badge-account-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Drawer left for normal navigation -->
    <v-navigation-drawer
      :value="$globalState.drawer"
      v-if="showPortfolioDrawer"
      fixed
      clipped
      :width="$globalState.drawerWidth"
      class="pt-16 mt-4 hidden-md-and-down"
    >
      <v-list nav>
        <v-list-item link to="/portfolio/#highlights">
          <v-list-item-content>
            <v-list-item-title class="pl-3 spacedletters3 text-uppercase"
              >Highlights</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <div v-for="genre in $globalState.genres" :key="genre.id">
          <v-list-item
            link
            :to="'/portfolio/' + genre.id"
            v-if="genre.id !== testGenreId"
          >
            <v-list-item-content>
              <v-list-item-title class="pl-3 spacedletters3 text-uppercase">{{
                genre.name
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link :to="'/portfolio/' + genre.id" v-else-if="$dev">
            <v-list-item-content>
              <v-list-item-title class="pl-3 spacedletters3 text-uppercase">{{
                genre.name
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="drawer" fixed class="hidden-lg-and-up">
      <v-row>
        <v-col class="d-flex justify-center pt-4">
          <J4pegIcon colorClass="text" :width="60" />
        </v-col>
      </v-row>

      <v-list>
        <v-list-item link to="/">
          <v-list-item-content>
            <v-list-item-title class="pl-4 spacedletters3 text-uppercase"
              >Home</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-group>
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="spacedletters3 text-uppercase"
                  >Portfolio</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item link to="/portfolio/#highlights">
            <v-list-item-content>
              <v-list-item-title class="pl-8 spacedletters3 text-uppercase"
                >Highlights</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <div v-for="genre in $globalState.genres" :key="genre.id">
            <v-list-item
              link
              :to="'/portfolio/' + genre.id"
              v-if="genre.id !== testGenreId"
            >
              <v-list-item-content>
                <v-list-item-title class="pl-8 spacedletters3 text-uppercase">{{
                  genre.name
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="'/portfolio/' + genre.id" v-else-if="$dev">
              <v-list-item-content>
                <v-list-item-title class="pl-8 spacedletters3 text-uppercase">{{
                  genre.name
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-group>
        <v-list-item link to="/#about">
          <v-list-item-content>
            <v-list-item-title class="pl-4 spacedletters3 text-uppercase"
              >Meet J4PEG Visuals</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/contact">
          <v-list-item-content>
            <v-list-item-title class="pl-4 spacedletters3 text-uppercase"
              >contact</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import adminMenu from "./adminMenu.vue";

import { supabase } from "../plugins/supabase/supabase";
import J4pegIcon from "./j4pegIcon.vue";
export default {
  name: "navigation2",
  props: {
    showPortfolioDrawer: Boolean,
  },
  data() {
    return {
      drawerItems: [],
      drawer: false,
      adminDrawer: false,
      testGenreId: "8a862ef2-9ddb-4682-8585-ed9e40be3e23",
    };
  },
  components: {
    adminMenu,
    J4pegIcon,
  },
  mounted() {
    if (this.$vuetify.breakpoint.mdAndDown) {
      this.$globalState.drawer = false;
    }
  },
  watch: {
    "$vuetify.breakpoint.mdAndDown": function () {
      this.$globalState.drawer = !this.$vuetify.breakpoint.mdAndDown;
    },
  },
  methods: {
    getGenreName: function (id) {
      return this.$globalState.genres.filter((genre) => {
        return genre.id === id;
      })[0].name;
    },
    handleSignOut: async function () {
      await supabase.auth.signOut();
      this.$globalState.isLoggedIn = false;
      this.$router.push({ name: "Home" });
    },
    handleAdminDrawer: function () {
      if (supabase.auth.session()) {
        this.adminDrawer = !this.adminDrawer;
      } else {
        this.$router.push({ name: "Login" });
      }
    },
  },
};
</script>
<style scoped>
.outline {
  outline-color: var(--v-text-base);
  outline: solid;
}
.corneroutline {
  /* width: 100px;
  height: 100px; */

  background: linear-gradient(to right, black 2px, transparent 2px) 0 0,
    /* linear-gradient(to right, black 4px, transparent 4px) 0 100%, */
      /* linear-gradient(to left, black 4px, transparent 4px) 100% 0, */
      linear-gradient(to left, black 2px, transparent 2px) 100% 100%,
    linear-gradient(to bottom, black 2px, transparent 2px) 0 0,
    /* linear-gradient(to bottom, black 4px, transparent 4px) 100% 0, */
      /* linear-gradient(to top, black 4px, transparent 4px) 0 100%, */
      linear-gradient(to top, black 2px, transparent 2px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 20px 20px;
}
.spacedletters {
  letter-spacing: 0.2em;
  font-size: 1.6vw;
  font-weight: 500;
}
.spacedletters2 {
  letter-spacing: 0.1em;
}
.spacedletters3 {
  letter-spacing: 0.2em;
}
.heading6 {
  font-size: 16px;
}
.heading4 {
  font-size: 34px;
  font-weight: bold;
}
</style>
