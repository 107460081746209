<template>
  <div>
    <v-container fluid class="pt-16">
      <v-row>
        <v-col class="hidden-sm-and-down" cols="2"></v-col>
        <v-col md="8">
          <v-card :elevation="$vuetify.breakpoint.smAndDown ? 0 : 3">
            <v-container>
              <v-row>
                <v-col cols="1"> </v-col>
                <v-col class="d-flex justify-center">
                  <span :style="headlineTextStyle">Genre Manager</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-center">
                  <v-dialog v-model="createGenreDialog" persistent>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="success"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        rounded
                      >
                        <v-icon color="success">mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <v-card
                      :style="
                        $vuetify.breakpoint.name === 'lg' ||
                        $vuetify.breakpoint.name === 'xl' ||
                        $vuetify.breakpoint.name === 'md'
                          ? 'width:20vw'
                          : ''
                      "
                    >
                      <v-card-title> Create a Genre </v-card-title>
                      <v-container>
                        <v-form v-model="validGenre">
                          <v-row>
                            <v-col>
                              <v-text-field
                                label="Title"
                                v-model="newGenre.name"
                                :rules="[notEmpty]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>

                        <v-row>
                          <v-col>
                            <v-col class="d-flex justify-end">
                              <v-btn
                                outlined
                                color="error"
                                @click="createGenreDialog = false"
                                class="mr-4"
                                >Cancel</v-btn
                              >
                              <v-btn
                                :disabled="!validGenre"
                                @click="createGenre"
                                :loading="createLoading"
                                outlined
                                color="success"
                                >Create</v-btn
                              >
                            </v-col>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card elevation="0">
                    <v-card-title>
                      <v-row>
                        <v-col class="d-fley align-center">
                          <v-text-field
                            label="Search Projects"
                            v-model="genreSearch"
                            prepend-icon="mdi-magnify"
                            color="secondary"
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex justify-end align-center">
                          <v-btn icon @click="getGenres" class="mr-4">
                            <v-icon :class="rotateClass" color="secondary"
                              >mdi-refresh</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-data-table
                      disable-pagination
                      multi-sort
                      hide-default-footer
                      :search="genreSearch"
                      :loading="genreTable.loading"
                      :headers="genreTable.headers"
                      :items="genreTable.items"
                    >
                      <template v-slot:[`item.updated`]="{ item }">
                        <span> {{ formatDate(item.updated) }} </span>
                      </template>
                      <template v-slot:[`item.created`]="{ item }">
                        <span> {{ formatDate(item.created) }} </span>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-dialog>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click="fillEditGenre(item)"
                            >
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <v-card style="width: 20vw">
                            <v-card-title>Edit Genre</v-card-title>
                            <v-container>
                              <v-form v-model="validEdit">
                                <v-row>
                                  <v-col>
                                    <v-text-field
                                      label="Title"
                                      v-model="editGenre.name"
                                      :rules="[notEmpty]"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-form>
                              <v-row>
                                <v-col>
                                  <v-col class="d-flex justify-end">
                                    <v-btn
                                      :disabled="!validEdit"
                                      @click="updateGenre(item)"
                                      :loading="updateLoading"
                                      outlined
                                      color="success"
                                      >Update</v-btn
                                    >
                                  </v-col>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </v-dialog>
                        <v-btn
                          icon
                          @click="
                            (showDeleteDialog = true) && (selectedGenre = item)
                          "
                        >
                          <v-icon color="error">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="showDeleteDialog">
        <v-card>
          <v-card-title> Delete Confirmation </v-card-title>
          <v-container>
            <v-row>
              <v-col>
                Are you sure you want to delete genre '{{
                  selectedGenre.name
                }}'?
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn
                  outlined
                  color="primary"
                  class="mr-4"
                  @click="showDeleteDialog = false"
                  >No, Cancel!</v-btn
                >
                <v-btn
                  outlined
                  @click="deleteGenre(selectedGenre)"
                  color="error"
                  >Yes, delete it!</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { supabase } from "../plugins/supabase/supabase";

export default {
  name: "ProjectManager",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Genre-Manager",
      meta: [
        {
          name: "description",
          content: this.$applicationName + "'s Genre Manager",
        },
      ],
    };
  },
  mounted() {
    this.getGenres();
  },
  data() {
    return {
      createGenreDialog: false,
      validGenre: false,
      validEdit: false,
      createLoading: false,
      updateLoading: false,
      genreSearch: null,
      showDeleteDialog: false,
      selectedGenre: {
        name: "",
      },
      newGenre: {
        name: null,
      },
      editGenre: {
        name: null,
      },
      genreTable: {
        loading: false,
        items: [],
        headers: [
          {
            text: "Name",
            align: "center",
            sortable: true,
            value: "name",
          },
          {
            text: "Projects",
            align: "center",
            sortable: true,
            value: "projectCount",
          },
          {
            text: "Images",
            align: "center",
            sortable: true,
            value: "imageCount",
          },
          {
            text: "Updated",
            align: "center",
            sortable: true,
            value: "updated",
          },
          {
            text: "Created",
            align: "center",
            sortable: true,
            value: "created",
          },
          {
            text: "Actions",
            align: "center",
            sortable: false,
            value: "actions",
          },
        ],
      },
    };
  },
  computed: {
    rotateClass: function () {
      if (this.genreTable.loading) {
        return "rotate";
      }
      return "";
    },
    headlineTextStyle: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "letter-spacing: 0.3em; font-size: 1.5em; font-weight: bold;";
        case "sm":
          return "letter-spacing: 0.3em; font-size: 1.7em; font-weight: bold;";
        case "md":
          return "letter-spacing: 0.3em; font-size: 2.3em; font-weight: bold;";
        case "lg":
          return "letter-spacing: 0.3em; font-size: 2.5em; font-weight: bold;";
        case "xl":
          return "letter-spacing: 0.3em; font-size: 2.5em; font-weight: bold;";
      }
      return "letter-spacing: 0.3em; font-size: 2.5em; font-weight: bold;";
    },
  },
  methods: {
    createGenre: async function () {
      this.createLoading = true;
      this.newGenre.created = Date.now();
      this.newGenre.updated = Date.now();
      let response = await supabase.from("genre").insert([this.newGenre]);

      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Genre Creation",
          message: response.error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Genre Creation",
          message: "Successfully created Genre '" + this.newGenre.name + "'",
        };
        this.$globalState.addNotification(notify);
        this.getGenres();
        this.createLoading = false;
        this.createGenreDialog = false;
      }
    },
    getGenres: async function () {
      this.genreTable.loading = true;
      let response = await supabase.from("genre").select();

      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Loading Genres",
          message: response.error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        this.genreTable.items = response.data;
        for (let i = 0; i < this.genreTable.items.length; i++) {
          this.genreTable.items[i].showDeleteDialog = false;
        }

        for (let i = 0; i < this.genreTable.items.length; i++) {
          response = await supabase
            .from("project")
            .select("*", { count: "exact", head: true })
            .match({ genre_id: this.genreTable.items[i].id });

          this.genreTable.items[i].projectCount = response.count;

          response = await supabase
            .from("image")
            .select("*", { count: "exact", head: true })
            .match({ genre_id: this.genreTable.items[i].id });

          this.genreTable.items[i].imageCount = response.count;
        }
      }
      this.genreTable.loading = false;
    },
    deleteGenre: async function (genre) {
      let response = await supabase
        .from("genre")
        .delete()
        .match({ id: genre.id });
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Deleting Genre",
          message: response.error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Deleting Genre",
          message: "Successfully deleted Genre",
        };
        this.$globalState.addNotification(notify);
        this.getGenres();
        this.showDeleteDialog = false;
      }
    },
    updateGenre: async function (genre) {
      this.updateLoading = true;
      let response = await supabase
        .from("genre")
        .update({
          name: this.editGenre.name,
        })
        .match({ id: genre.id });

      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Updating Genre",
          message: response.error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Updating Genre",
          message: "Successfully updated Genre",
        };
        this.$globalState.addNotification(notify);
        this.getGenres();
      }

      this.updateLoading = false;
    },
    fillEditGenre: function (genre) {
      this.editGenre.name = genre.name;
    },
    formatDate: function (epoch) {
      let date = new Date(epoch);
      let day = ("0" + date.getDate()).slice(-2);
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let year = date.getFullYear();
      let hours = ("0" + date.getHours()).slice(-2);
      let minutes = ("0" + date.getMinutes()).slice(-2);
      let seconds = ("0" + (date.getSeconds() + 1)).slice(-2);

      let timestamp =
        day +
        "." +
        month +
        "." +
        year +
        " - " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;

      return timestamp;
    },

    //rules
    notEmpty: function (input) {
      if (input) {
        return true;
      }
      return "This field is required";
    },
  },
};
</script>
<style scoped>
.rotate {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.truncate {
  max-width: 16vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
