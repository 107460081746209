<template>
  <div>
    <v-menu v-if="$globalState.isLoggedIn">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon class="ma-4" v-bind="attrs" v-on="on">
          <v-icon
            :color="
              $vuetify.breakpoint.mdAndDown && $route.name === 'Home'
                ? 'background'
                : 'primary'
            "
            large
            >mdi-account</v-icon
          >
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon large>mdi-account</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-if="$globalState.profile">{{
                $globalState.profile.display_name
              }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn @click="handleSignOut" icon>
                <v-icon>mdi-logout</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item to="/cdnmanager">
            <v-list-item-icon>
              <v-icon>mdi-cloud-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>CDN-Manager</v-list-item-title>
          </v-list-item>

          <v-list-item to="/projectmanager">
            <v-list-item-icon>
              <v-icon>mdi-folder-image</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Project-Manager</v-list-item-title>
          </v-list-item>

          <v-list-item to="/genremanager">
            <v-list-item-icon>
              <v-icon>mdi-palette-swatch</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Genre-Manager</v-list-item-title>
          </v-list-item>

          <v-list-item to="/profile">
            <v-list-item-icon>
              <v-icon>mdi-badge-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <v-btn class="ma-4" v-else icon to="/login">
      <v-icon large color="primary">mdi-account</v-icon>
    </v-btn>
  </div>
</template>
<script>
import { supabase } from "../plugins/supabase/supabase";
export default {
  name: "adminMenu",
  methods: {
    handleSignOut: async function () {
      await supabase.auth.signOut();
      this.$globalState.isLoggedIn = false;
      this.$router.push({ name: "Home" });
    },
  },
};
</script>
<style scoped></style>
