<template>
  <v-app class="app">
    <v-main>
      <v-container fluid class="ma-0 pa-0">
        <navigation
          v-if="$route.meta.drawer"
          :showPortfolioDrawer="
            $route.name === 'Portfolio' || $route.name === 'PortfolioItem'
          "
        />
        <router-view />
      </v-container>
    </v-main>
    <notfiy />
  </v-app>
</template>

<script>
import navigation from "./components/navigation.vue";
import notfiy from "./components/notification.vue";
export default {
  name: "App",
  metaInfo() {
    return {
      // all titles will be injected into this template
      titleTemplate: "%s | " + this.$applicationName,
    };
  },
  components: {
    navigation,
    notfiy,
  },
};
</script>

<style>
.app {
  /* overflow-y: auto; */
  /* overflow-x: hidden; */

  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

/* FONT */

@font-face {
  font-family: "AvenirNext";
  src: local("AvenirNext"),
    url(./fonts/avenir/AvenirNextLTPro-Regular.otf) format("opentype");
}

.app {
  font-family: "AvenirNext";
}

/* width / */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* / Track / */
::-webkit-scrollbar-track {
  background: var(--v-primary-base);
}

/* / Handle / */
::-webkit-scrollbar-thumb {
  background: var(--v-background-base);
}

/* / Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--v-secondary-base);
}
</style>
