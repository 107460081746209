<template>
  <div>
    <v-container fluid class="pt-16">
      <v-row>
        <v-col class="hidden-sm-and-down" cols="2"></v-col>
        <v-col md="8">
          <v-card :elevation="$vuetify.breakpoint.smAndDown ? 0 : 3">
            <v-container>
              <v-row>
                <v-col cols="1"> </v-col>
                <v-col class="d-flex justify-center">
                  <span :style="headlineTextStyle">Project Manager</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-center">
                  <v-dialog v-model="createProjectDialog" persistent>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="success"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        rounded
                      >
                        <v-icon color="success">mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <v-card
                      :style="
                        $vuetify.breakpoint.name === 'lg' ||
                        $vuetify.breakpoint.name === 'xl' ||
                        $vuetify.breakpoint.name === 'md'
                          ? 'width:20vw'
                          : ''
                      "
                    >
                      <v-card-title> Create a Project </v-card-title>
                      <v-container>
                        <v-form ref="projectForm" v-model="validProject">
                          <v-row>
                            <v-col>
                              <v-text-field
                                label="Title"
                                v-model="newProject.title"
                                :rules="[notEmpty]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-select
                                :items="genres"
                                label="Genre"
                                v-model="newProject.genre_id"
                                item-value="id"
                                item-text="name"
                              ></v-select>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <v-textarea
                                label="Description"
                                v-model="newProject.description"
                                :rules="[notEmpty]"
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </v-form>
                        <v-row>
                          <v-col>
                            <v-checkbox
                              label="Highlight Project"
                              v-model="newProject.highlight"
                            ></v-checkbox>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col>
                            <v-col class="d-flex justify-end">
                              <v-btn
                                outlined
                                color="error"
                                @click="createProjectDialog = false"
                                class="mr-4"
                                >Cancel</v-btn
                              >
                              <v-btn
                                :disabled="!validProject"
                                @click="createProject"
                                :loading="createLoading"
                                outlined
                                color="success"
                                >Create</v-btn
                              >
                            </v-col>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-card elevation="0">
                    <v-card-title>
                      <v-row>
                        <v-col class="d-fley align-center">
                          <v-text-field
                            label="Search Projects"
                            v-model="projectSearch"
                            prepend-icon="mdi-magnify"
                            color="secondary"
                          ></v-text-field>
                        </v-col>
                        <v-col class="d-flex justify-end align-center">
                          <v-btn icon @click="getProjects" class="mr-4">
                            <v-icon :class="rotateClass" color="secondary"
                              >mdi-refresh</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-title>
                    <v-data-table
                      disable-pagination
                      multi-sort
                      hide-default-footer
                      :search="projectSearch"
                      :loading="projectTable.loading"
                      :headers="projectTable.headers"
                      :items="projectTable.items"
                    >
                      <template v-slot:[`item.description`]="{ item }">
                        <v-tooltip bottom max-width="50vw">
                          <template v-slot:activator="{ on, attrs }">
                            <td v-bind="attrs" v-on="on" class="truncate">
                              {{ item.description }}
                            </td>
                          </template>
                          <div>{{ item.description }}</div>
                        </v-tooltip>
                      </template>
                      <template v-slot:[`item.highlight`]="{ item }">
                        <span
                          v-if="
                            item.highlight != false && item.highlight != true
                          "
                          >N.A.</span
                        >
                        <v-icon v-else-if="item.highlight">mdi-check</v-icon>
                        <span v-else>-</span>
                      </template>
                      <template v-slot:[`item.updated`]="{ item }">
                        <span> {{ formatDate(item.updated) }} </span>
                      </template>
                      <template v-slot:[`item.created`]="{ item }">
                        <span> {{ formatDate(item.created) }} </span>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-btn
                          icon
                          @click="
                            (showEditDialog = true) &&
                              (selectedProject = item) &&
                              fillEditProject(item)
                          "
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          @click="
                            (showDeleteDialog = true) &&
                              (selectedProject = item)
                          "
                        >
                          <v-icon color="error">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog v-model="showEditDialog">
        <v-card style="width: 20vw">
          <v-card-title>Edit Project</v-card-title>
          <v-container>
            <v-form v-model="validEdit">
              <v-row>
                <v-col>
                  <v-text-field
                    label="Title"
                    v-model="editProject.title"
                    :rules="[notEmpty]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    :items="genres"
                    label="Genre"
                    v-model="editProject.genre_id"
                    item-value="id"
                    item-text="name"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    label="Description"
                    v-model="editProject.description"
                    :rules="[notEmpty]"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col>
                <v-checkbox
                  label="Highlight Project"
                  v-model="editProject.highlight"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-col class="d-flex justify-end">
                  <v-btn
                    :disabled="!validEdit"
                    @click="updateProject(selectedProject)"
                    :loading="updateLoading"
                    outlined
                    color="success"
                    >Update</v-btn
                  >
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showDeleteDialog">
        <v-card>
          <v-card-title> Delete Confirmation </v-card-title>
          <v-container>
            <v-row>
              <v-col>
                Are you sure you want to delete project '{{
                  selectedProject.title
                }}' and all of its {{ selectedProject.imageCount }} images?
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-btn
                  outlined
                  color="primary"
                  class="mr-4"
                  @click="showDeleteDialog = false"
                  >No, Cancel!</v-btn
                >
                <v-btn
                  outlined
                  @click="deleteProject(selectedProject)"
                  color="error"
                  :loading="deleteLoading"
                  >Yes, delete it!</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import { supabase } from "../plugins/supabase/supabase";
import { DeleteObjectCommand } from "@aws-sdk/client-s3";
import { createClient, bucketName } from "../plugins/s3/s3client";
export default {
  name: "ProjectManager",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Project-Manager",
      meta: [
        {
          name: "description",
          content: this.$applicationName + "'s Project Manager",
        },
      ],
    };
  },
  async mounted() {
    await this.getGenres();
    this.getProjects();
  },
  data() {
    return {
      genres: [],
      createProjectDialog: false,
      validProject: false,
      validEdit: false,
      createLoading: false,
      updateLoading: false,
      deleteLoading: false,
      projectSearch: null,
      showDeleteDialog: false,
      showEditDialog: false,
      selectedProject: {
        name: "",
      },
      newProject: {
        title: null,
        genre_id: null,
        description: null,
        highlight: false,
      },
      editProject: {
        title: null,
        genre_id: null,
        description: null,
        highlight: false,
      },
      projectTable: {
        loading: false,
        items: [],
        headers: [
          {
            text: "Title",
            align: "center",
            sortable: true,
            value: "title",
          },
          {
            text: "Description",
            align: "center",
            sortable: true,
            value: "description",
          },
          {
            text: "Genre",
            align: "center",
            sortable: true,
            value: "genre",
          },
          {
            text: "Images",
            align: "center",
            sortable: true,
            value: "imageCount",
          },
          {
            text: "Highlight",
            align: "center",
            sortable: true,
            value: "highlight",
          },
          {
            text: "Updated",
            align: "center",
            sortable: true,
            value: "updated",
          },
          {
            text: "Created",
            align: "center",
            sortable: true,
            value: "created",
          },
          {
            text: "Actions",
            align: "center",
            sortable: false,
            value: "actions",
          },
        ],
      },
    };
  },
  computed: {
    rotateClass: function () {
      if (this.projectTable.loading) {
        return "rotate";
      }
      return "";
    },
    headlineTextStyle: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "letter-spacing: 0.3em; font-size: 1.5em; font-weight: bold;";
        case "sm":
          return "letter-spacing: 0.3em; font-size: 1.7em; font-weight: bold;";
        case "md":
          return "letter-spacing: 0.3em; font-size: 2.3em; font-weight: bold;";
        case "lg":
          return "letter-spacing: 0.3em; font-size: 2.5em; font-weight: bold;";
        case "xl":
          return "letter-spacing: 0.3em; font-size: 2.5em; font-weight: bold;";
      }
      return "letter-spacing: 0.3em; font-size: 2.5em; font-weight: bold;";
    },
  },
  methods: {
    createProject: async function () {
      this.createLoading = true;
      this.newProject.created = Date.now();
      this.newProject.updated = Date.now();
      let response = await supabase.from("project").insert([this.newProject]);

      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Project Creation",
          message: response.error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Project Creation",
          message:
            "Successfully created Project '" + this.newProject.title + "'",
        };
        this.$globalState.addNotification(notify);
        this.getProjects();
        this.createProjectDialog = false;
        this.$refs.projectForm.reset();
      }
      this.createLoading = false;
    },
    getProjects: async function () {
      this.projectTable.loading = true;
      let response = await supabase.from("project").select();

      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Loading Projects",
          message: response.error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        this.projectTable.items = response.data;
        for (let i = 0; i < this.projectTable.items.length; i++) {
          this.projectTable.items[i].showDeleteDialog = false;

          response = await supabase
            .from("genre")
            .select()
            .match({ id: this.projectTable.items[i].genre_id });

          if (response.data) {
            this.projectTable.items[i].genre = response.data[0].name;
          } else {
            this.projectTable.items[i].genre = "N/A";
          }

          response = await supabase
            .from("image")
            .select("*", { count: "exact" })
            .match({ project_id: this.projectTable.items[i].id });
          if (response.count != null) {
            this.projectTable.items[i].imageCount = response.count;
          } else {
            this.projectTable.items[i].imageCount = "N/A";
          }
          if (response.data && response.data.length > 0) {
            this.projectTable.items[i].images = response.data;
          } else {
            this.projectTable.items[i].images = [];
          }
        }
      }
      this.projectTable.loading = false;
    },
    deleteProject: async function (project) {
      this.deleteLoading = true;
      let response = await supabase
        .from("project")
        .delete()
        .match({ id: project.id });
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Deleting Project",
          message: response.error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        for (let i = 0; i < project.images.length; i++) {
          await this.deleteFile(project.images[i]);
        }
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Deleting Project",
          message: "Successfully deleted Project",
        };
        this.$globalState.addNotification(notify);
        this.getProjects();
        this.showDeleteDialog = false;
      }
      this.deleteLoading = false;
    },
    deleteFile: async function (item) {
      //CDN
      let s3Client = await createClient();
      for (let i = 0; i < this.$globalState.imageVariants.length; i++) {
        const bucketParams = {
          Bucket: bucketName,
          Key: item.key.replace(
            "BREAKPOINT",
            this.$globalState.imageVariants[i].breakpoint
          ),
        };
        try {
          await s3Client.send(new DeleteObjectCommand(bucketParams));
        } catch (err) {
          console.log("Error", err);
        }
      }

      item.showDeleteDialog = false;
    },
    updateProject: async function (project) {
      this.updateLoading = true;
      let response = await supabase
        .from("project")
        .update({
          title: this.editProject.title,
          genre_id: this.editProject.genre_id,
          description: this.editProject.description,
          highlight: this.editProject.highlight,
          updated: Date.now(),
        })
        .match({ id: project.id });

      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Updating Project",
          message: response.error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        let notify = {
          duration: 4000,
          type: "success",
          headline: "Updating Project",
          message: "Successfully updated Project",
        };
        this.$globalState.addNotification(notify);
        this.getProjects();
      }
      this.showEditDialog = false;
      this.updateLoading = false;
    },
    getGenres: async function () {
      let response = await supabase.from("genre").select();

      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Loading Genres",
          message: response.error.message,
        };
        this.$globalState.addNotification(notify);
      } else {
        this.genres = response.data;
      }
    },
    fillEditProject: function (project) {
      this.editProject.title = project.title;
      this.editProject.genre_id = project.genre_id;
      this.editProject.description = project.description;
      this.editProject.highlight = project.highlight;
    },
    closeDialog: function (project) {
      project.showDeleteDialog = false;
    },
    formatDate: function (epoch) {
      let date = new Date(epoch);
      let day = ("0" + date.getDate()).slice(-2);
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let year = date.getFullYear();
      let hours = ("0" + date.getHours()).slice(-2);
      let minutes = ("0" + date.getMinutes()).slice(-2);
      let seconds = ("0" + (date.getSeconds() + 1)).slice(-2);

      let timestamp =
        day +
        "." +
        month +
        "." +
        year +
        " - " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;

      return timestamp;
    },

    //rules
    notEmpty: function (input) {
      if (input) {
        return true;
      }
      return "This field is required";
    },
  },
};
</script>
<style scoped>
.rotate {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.truncate {
  max-width: 16vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
