<template>
  <div>
    <v-row>
      <v-col>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-on="on"
              v-bind="attrs"
              @click="copyProjectUrl"
              class="heading5 font-weight-bold"
            >
              {{ project.title }}
            </span>
          </template>
          <span>Click to copy the link to this project</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0">
        <div>
          {{ project.description }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div :class="isMobile ? 'gridContainerMobile' : 'gridContainerDesktop'">
          <div class="titleImg">
            <imageDetail
              v-if="titleImage"
              :src="titleImage.key"
              :titleImage="true"
              v-on:changeTitleImage="extractTitleImage"
            />
          </div>
          <div
            v-for="(image, index) in projectImages"
            :key="index"
            :class="'img' + (index + 1)"
          >
            <imageDetail :src="image.key" />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { supabase } from "../plugins/supabase/supabase";

import imageDetail from "../components/imageDetail.vue";
export default {
  name: "projectGrid",
  props: {
    project: Object,
  },
  components: {
    imageDetail,
  },
  data() {
    return {
      titleImage: null,
      projectImages: null,
    };
  },
  async mounted() {
    await this.extractTitleImage();
  },
  computed: {
    isMobile: function () {
      if (this.$vuetify.breakpoint.width > 700) {
        return false;
      }
      return true;
    },
  },
  methods: {
    extractTitleImage: async function () {
      let images = await this.getProjectImages(this.project.id);
      for (let i = 0; i < images.length; i++) {
        if (images[i].title) {
          this.titleImage = images.splice(i, 1)[0];
        }
      }

      this.projectImages = images;
    },
    getProjectImages: async function (projectId) {
      let response = await supabase
        .from("image")
        .select()
        .match({ project_id: projectId });
      if (response.error) {
        let notify = {
          duration: 4000,
          type: "error",
          headline: "Loading Images",
          message: "Failed loading Images. Please try again later",
        };
        this.$globalState.addNotification(notify);
      } else if (response.data && response.data.length > 0) {
        return response.data;
      }
    },
    copyProjectUrl: function () {
      this.$clipboard(
        this.$appUrl + this.$route.path + "?p=" + this.project.id
      );
      let notify = {
        duration: 4000,
        type: "success",
        headline: "Successfully copied Link for '" + this.project.title + "'",
        message: "",
      };
      this.$globalState.addNotification(notify);
    },
  },
};
</script>
<style scoped>
.heading5 {
  font-size: 18px;
}

.gridContainerDesktop {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  gap: 10px 10px;
  grid-template-areas:
    "titleImg titleImg img1 img2 img3 img4"
    "titleImg titleImg img5 img6 img7 img8";
}
.gridContainerMobile {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(6, minmax(0, 1fr));
  gap: 10px 10px;
  grid-template-areas:
    "titleImg titleImg"
    "titleImg titleImg"
    "img1 img2"
    "img3 img4"
    "img5 img6"
    "img7 img8";
}

.titleImg {
  grid-area: titleImg;
}
.img1 {
  grid-area: img1;
}
.img2 {
  grid-area: img2;
  width: 100%;
  max-height: 100%;
}
.img3 {
  grid-area: img3;
}
.img4 {
  grid-area: img4;
}
.img5 {
  grid-area: img5;
}
.img6 {
  grid-area: img6;
}
.img7 {
  grid-area: img7;
}
.img8 {
  grid-area: img8;
}
</style>
