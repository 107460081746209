var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"pt-16",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"hidden-sm-and-down",attrs:{"cols":"2"}}),_c('v-col',{attrs:{"md":"8"}},[_c('v-card',{attrs:{"elevation":_vm.$vuetify.breakpoint.smAndDown ? 0 : 3}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{staticClass:"d-flex justify-center"},[_c('span',{style:(_vm.headlineTextStyle)},[_vm._v("Genre Manager")])])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-dialog',{attrs:{"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","outlined":"","rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.createGenreDialog),callback:function ($$v) {_vm.createGenreDialog=$$v},expression:"createGenreDialog"}},[_c('v-card',{style:(_vm.$vuetify.breakpoint.name === 'lg' ||
                      _vm.$vuetify.breakpoint.name === 'xl' ||
                      _vm.$vuetify.breakpoint.name === 'md'
                        ? 'width:20vw'
                        : '')},[_c('v-card-title',[_vm._v(" Create a Genre ")]),_c('v-container',[_c('v-form',{model:{value:(_vm.validGenre),callback:function ($$v) {_vm.validGenre=$$v},expression:"validGenre"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Title","rules":[_vm.notEmpty]},model:{value:(_vm.newGenre.name),callback:function ($$v) {_vm.$set(_vm.newGenre, "name", $$v)},expression:"newGenre.name"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"error"},on:{"click":function($event){_vm.createGenreDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"disabled":!_vm.validGenre,"loading":_vm.createLoading,"outlined":"","color":"success"},on:{"click":_vm.createGenre}},[_vm._v("Create")])],1)],1)],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"d-fley align-center"},[_c('v-text-field',{attrs:{"label":"Search Projects","prepend-icon":"mdi-magnify","color":"secondary"},model:{value:(_vm.genreSearch),callback:function ($$v) {_vm.genreSearch=$$v},expression:"genreSearch"}})],1),_c('v-col',{staticClass:"d-flex justify-end align-center"},[_c('v-btn',{staticClass:"mr-4",attrs:{"icon":""},on:{"click":_vm.getGenres}},[_c('v-icon',{class:_vm.rotateClass,attrs:{"color":"secondary"}},[_vm._v("mdi-refresh")])],1)],1)],1)],1),_c('v-data-table',{attrs:{"disable-pagination":"","multi-sort":"","hide-default-footer":"","search":_vm.genreSearch,"loading":_vm.genreTable.loading,"headers":_vm.genreTable.headers,"items":_vm.genreTable.items},scopedSlots:_vm._u([{key:"item.updated",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.updated))+" ")])]}},{key:"item.created",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.created))+" ")])]}},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.fillEditGenre(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('v-card',{staticStyle:{"width":"20vw"}},[_c('v-card-title',[_vm._v("Edit Genre")]),_c('v-container',[_c('v-form',{model:{value:(_vm.validEdit),callback:function ($$v) {_vm.validEdit=$$v},expression:"validEdit"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Title","rules":[_vm.notEmpty]},model:{value:(_vm.editGenre.name),callback:function ($$v) {_vm.$set(_vm.editGenre, "name", $$v)},expression:"editGenre.name"}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"disabled":!_vm.validEdit,"loading":_vm.updateLoading,"outlined":"","color":"success"},on:{"click":function($event){return _vm.updateGenre(item)}}},[_vm._v("Update")])],1)],1)],1)],1)],1)],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){(_vm.showDeleteDialog = true) && (_vm.selectedGenre = item)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1)],1)],1)],1)],1)],1),_c('v-dialog',{model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Delete Confirmation ")]),_c('v-container',[_c('v-row',[_c('v-col',[_vm._v(" Are you sure you want to delete genre '"+_vm._s(_vm.selectedGenre.name)+"'? ")])],1),_c('v-row',[_c('v-col',[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){_vm.showDeleteDialog = false}}},[_vm._v("No, Cancel!")]),_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":function($event){return _vm.deleteGenre(_vm.selectedGenre)}}},[_vm._v("Yes, delete it!")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }