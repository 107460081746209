var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"fileInput"},[_c('v-file-input',{attrs:{"counter":"","show-size":"","truncate-length":"15","multiple":""},on:{"change":_vm.prepareFiles},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',[(_vm.files.length > 0 && _vm.images.length === _vm.files.length)?_c('v-expansion-panels',{staticClass:"panel",attrs:{"flat":""}},_vm._l((_vm.images),function(image,index){return _c('v-expansion-panel',{key:image.src + 'd'},[_c('v-expansion-panel-header',{on:{"click":_vm.resetPreview}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_vm._v(" "+_vm._s(image.name)+" - ("+_vm._s(image.width)+" x "+_vm._s(image.height)+") ")]),_c('v-col',{staticClass:"d-flex justify-end align-center"},[(image.cropped)?_c('v-icon',{staticClass:"mx-2",attrs:{"color":"primary"}},[_vm._v("mdi-crop")]):_vm._e(),(image.ratio != 1)?_c('v-tooltip',{attrs:{"left":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle")])]}}],null,true)},[_c('span',[_vm._v("Must be cropped!")])]):_c('v-icon',{staticClass:"mx-2",attrs:{"color":"success"}},[_vm._v("mdi-check")])],1)],1)],1),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('Span',[_vm._v("Crop")])],1),_c('v-col',{staticClass:"d-flex justify-center"},[_c('span',[_vm._v("Preview")])])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('div',{style:(_vm.cropperWrapperStyle)},[_c('div',{staticClass:"image-background",style:({ backgroundImage: 'url(' + image.src + ')' })}),_c('Cropper',{style:(_vm.cropperStyle),attrs:{"src":image.src,"debounce":false,"stencil-props":{
                    aspectRatio: 1,
                  },"default-position":{
                    top: 0,
                    left: 0,
                  },"default-size":_vm.defaultSize,"background-class":"cropper-background"},on:{"change":_vm.onChange}})],1)]),_c('v-col',{staticClass:"d-flex justify-center"},[_c('Preview',{attrs:{"width":_vm.previewWidth,"height":_vm.previewWidth,"image":_vm.result.image,"coordinates":_vm.result.coordinates}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center align-center"},[_c('v-btn',{attrs:{"disabled":!image.src,"color":"primary"},on:{"click":function($event){return _vm.crop(index)}}},[_vm._v(" Crop ")])],1)],1)],1)],1)}),1):_vm._e()],1):_c('div',[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center font-weight-bold"},[_vm._v(" Crop is only available on Tablets and Desktops ")])],1)],1),_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"error"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v("Back")]),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","disabled":_vm.files.length === 0 || !_vm.allImgRatio1},on:{"click":_vm.selectImages}},[_vm._v(" Continue ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }