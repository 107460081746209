<template>
  <v-img
    :src="imgSrc"
    :lazy-src="lazySrc"
    @click="$router.push({ path: '/portfolio/' + genreId })"
  >
    <div style="height: 100%; position: relative" class="growblur">
      <div
        class="portfoliocard pa-n2 background--text center text-uppercase"
        :style="titleStyle"
      >
        {{ title }}
      </div>
    </div>
  </v-img>
</template>
<script>
export default {
  name: "portfolioCard",
  props: {
    title: String,
    imgSrc: String,
    lazySrc: String,
    genreId: String,
  },
  computed: {
    titleStyle: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "letter-spacing: 0.6em; font-size: 1.4em;";
        case "sm":
          return "letter-spacing: 0.6em; font-size: 1.4em;";
        case "md":
          return "letter-spacing: 0.7em; font-size: 1.1em;";
        case "lg":
          return "letter-spacing: 0.7em; font-size: 1.5em;";
        case "xl":
          return "letter-spacing: 0.7em; font-size: 1.5em;";
      }
      return "letter-spacing: 0.7em; font-size: 1.5em;";
    },
  },
};
</script>
<style scoped>
.growblur {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.growblur:hover {
  transform: scale(1.1);
  backdrop-filter: blur(3px);
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}
.spacedletters6 {
  letter-spacing: 0.7em;
  font-size: 1.4vw;
}
.center {
  height: 100%;
  display: grid;
  place-items: center;
}
.portfoliocard {
  height: 30%;
  width: 100%;

  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
