import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Meta from "vue-meta";
import Clipboard from "v-clipboard";

Vue.use(Clipboard);

//Meta
Vue.use(Meta);

Vue.prototype.$applicationName = "J4PEG Visuals";
Vue.prototype.$cdnUrl = "https://j4rg-cdn.fra1.cdn.digitaloceanspaces.com/";
Vue.prototype.$appUrl = "https://j4peg.m8motion.com";

Vue.config.productionTip = false;

Vue.prototype.$dev = Vue.config.devtools;

if (Vue.prototype.$dev) {
  Vue.prototype.$appUrl = "http://localhost:8080";
}

const store = Vue.observable({
  genres: [],
  genreProjects: [],
  imageVariants: [
    {
      type: "image/webp",
      breakpoint: "xs",
      width: 600,
    },
    {
      type: "image/webp",
      breakpoint: "sm",
      width: 900,
    },
    {
      type: "image/webp",
      breakpoint: "md",
      width: 1260,
    },
    {
      type: "image/webp",
      breakpoint: "lg",
      width: 1900,
    },
    {
      type: "image/webp",
      breakpoint: "xl",
      width: 2200,
    },
    {
      type: null,
      breakpoint: "og",
      width: -1,
    },
  ],
  drawer: true,
  drawerWidth: 180,
  notifications: [],
  addNotification(notification) {
    store.notifications.push(notification);
  },
  getAndRemoveNotification() {
    return store.notifications.shift();
  },
  isLoggedIn: false,
  profile: null,
});

Vue.prototype.$globalState = store;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
