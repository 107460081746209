<template>
  <div>
    <v-container fluid class="mt-16">
      <v-row>
        <v-col class="hidden-sm-and-down" cols="2"></v-col>
        <v-col md="8">
          <v-card :elevation="$vuetify.breakpoint.smAndDown ? 0 : 3">
            <v-container>
              <v-row>
                <v-col class="d-flex justify-center">
                  <span :style="headlineTextStyle">CDN Manager</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-center">
                  <v-dialog v-model="uploadDialog" persistent>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="success"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        rounded
                      >
                        <v-icon color="success">mdi-upload</v-icon>
                      </v-btn>
                    </template>
                    <cdnUpload
                      v-on:closeUpload="uploadDialog = false"
                      v-on:fetchImages="$refs.cdnTable.fillTable()"
                    />
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <!-- TODO add cdnTable here -->
                  <cdnTable ref="cdnTable" />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import cdnUpload from "../components/cdnUpload.vue";
import cdnTable from "../components/cdnTable.vue";
export default {
  name: "CdnManager2",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "CDN-Manager",
      meta: [
        {
          name: "description",
          content: this.$applicationName + "'s CDN Manager",
        },
      ],
    };
  },
  data() {
    return {
      uploadDialog: false,
    };
  },
  components: {
    cdnUpload,
    cdnTable,
  },
  computed: {
    headlineTextStyle: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "letter-spacing: 0.3em; font-size: 1.5em; font-weight: bold;";
        case "sm":
          return "letter-spacing: 0.3em; font-size: 1.7em; font-weight: bold;";
        case "md":
          return "letter-spacing: 0.3em; font-size: 2.3em; font-weight: bold;";
        case "lg":
          return "letter-spacing: 0.3em; font-size: 2.5em; font-weight: bold;";
        case "xl":
          return "letter-spacing: 0.3em; font-size: 2.5em; font-weight: bold;";
      }
      return "letter-spacing: 0.3em; font-size: 2.5em; font-weight: bold;";
    },
  },
};
</script>
<style scoped></style>
